import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Helmet } from 'react-helmet';

import Layout from '../components/Layout';

import _404Image from '../assets/images/404-image.svg';

export default () => (
  <Layout>
    <Helmet>
      <title>404 - Replaix</title>
      <meta name="robots" content="noindex,follow"/>
    </Helmet>
    <Grid item xs={12} lg={8} style={{
      margin: '0 auto',
      padding: '96px 24px'
    }}>
      <img src={_404Image} alt="page not found" width="100%"/>
    </Grid>
  </Layout>
)
